import React from "react";
import { MethodTabsFrontMatter } from "types/graphql";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Link } from "gatsby";
import Section from "../section";
import BaseButton from "components/button/BaseButton";
import classNames from "classnames/bind";
import ArrowRight from "assets/svg/arrow-right.svg";
import {isDesktop} from "react-device-detect";
import PlusIcon from "assets/svg/plus.svg";

type tabMethodProps = Omit<MethodTabsFrontMatter, "format"> & {
  content: string;
};

const goalsData = ({
  title,
  content,
  listtitle,
  listcontent,
  subtitle,
}: tabMethodProps) => {

  return (
    <Section id="goals" className="js-section relative flex flex-col bg-gray-900" full>
      <h2 className="text-center text-white">{title}</h2>
      <p className="text-h3 text-center mb-14 text-gray-500">{subtitle}</p>
      <ol className={"flex flex-wrap gap-10"}>
        {
          listtitle && listtitle.map((item: string, index: number) => {
            return (
              <li
                key={index}
                className={classNames(
                  "relative min-w-[278px] flex-1 group bg-gray-900 flex flex-col items-center gap-y-5 rounded-xl p-7 transition-all duration-03 ease-ease text-white",
                  "before:absolute before:left-0 before:top-0 before:h-full before:w-full before:shadow-green before:rounded-xl"
                )}
              >
                <div className={"size-16 w-16 h-16 bg-no-repeat bg-center bg-atom text-green-500 grid place-items-center"}>
                  {index+1}
                </div>
                <span className="w-full h3 transition-all duration-02 ease-ease text-center block">
                  {item}
                </span>
                <p className="text-b3 text-center transition-all duration-03 ease-ease" dangerouslySetInnerHTML={{ __html: listcontent ? listcontent[index] : 0 }} />
              </li>
            );
          })
        }
      </ol>


      {content && (
        <div
          className="mb-6"
          dangerouslySetInnerHTML={{__html: content}}
        />
      )}
    </Section>
  );
};

export default goalsData;
