import React from "react";
import { solutionDataProps } from "types/graphql";
import Section from "../section";
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/data/solutions/)/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            icon
            title
            list
          }
        }
      }
    }
  }
`;

type SolutionSectionDataProps = {
  title: string,
  subtitle: string,
  informations: string
}

const solutionsData = ({
  title,
  subtitle,
  informations
}: SolutionSectionDataProps) => {
  const solutionsQuery = useStaticQuery(query);
  const {
    allMarkdownRemark: { edges: solutions },
  } = solutionsQuery;

  return (
    <Section id="solutions" className="js-section relative flex flex-col bg-data-100" full>
      <div className="max-w-7xl px-5">
        <h2 className="text-h2 text-center text-data-700 font-bold">{title}</h2>
        <p className="text-subtitle1 text-center text-data-700">{subtitle}</p>
        <div className="flex flex-wrap -mx-5">
        {solutions.map(({ node: { frontmatter: elem, html } } : solutionDataProps, index: number) => {
          return (
          <div key={index} className="w-full md:w-1/2 px-5 mt-10 md:mt-50px">
            <div className="relative h-full">
              <div className="absolute top-0 left-0 w-full h-full rounded-20px bg-data-700 transform translate-x-2.5 translate-y-2.5 z-0"></div>
              <div className="p-30px h-full rounded-20px border border-data-700 z-10 relative bg-data-100">
                <div className="flex items-center">
                  <div className="inline-flex items-center justify-center w-12 h-12 bg-data-700 rounded-10px mr-5">
                    <img className="w-7 h-auto" src={elem.icon} alt={elem.title} />
                  </div>
                  <h3 className="text-h3 text-data-700 font-bold">{elem.title}</h3>
                </div>
                <div className="mt-2.5 flex flex-wrap items-center justify-start -mx-5px">
                  {elem.list.map((item: string, index: number) => {
                    return (
                      <div key={index} className="px-5px mt-2.5">
                        <div className="text-caption1 font-bold py-2 px-3.5 rounded-full bg-data-200 text-data-700">
                          {item}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          );
        })}
        </div>
        <div className="flex items-center mt-50px rounded-20px p-5 bg-data-200">
          <img src="/data/info.svg" alt="info" className="w-7 h-auto" />
          <p className="flex-grow text-center text-subtitle1 text-data-700 font-medium">
            {informations}
          </p>
        </div>
      </div>
    </Section>
  );
};

export default solutionsData;
