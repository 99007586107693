import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { ListBannerFrontMatter, successProps } from "types/graphql";

import ArrowRight from "assets/svg/arrow-right.svg";

type listBannerProps = Omit<ListBannerFrontMatter, "format">;

const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/data/success/)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          html
          frontmatter {
            image
            title
            theme
            goal
            svg
            listitems
          }
        }
      }
    }
  }
`;

const listBannerContent = ({ title, subtitle, id }: listBannerProps) => {
  const successQuery = useStaticQuery(query);
  const {
    allMarkdownRemark: { edges: successes },
  } = successQuery;

  return (
    <section
      id="success"
      data-name="list-success"
      className="js-section py-8 lg:py-32 bg-gray-50 relative gap-y-8 px-5 md:px-0"
    >
      <div
        id={id}
        className="absolute left-0 -top-32 lg:-top-16 h-px w-px pointer-events-none opacity-0"
      />
      <h2 className="text-center block">{title}</h2>
      <p
        data-aos="custom"
        data-offset="3.8"
        className="text-h3 text-center mb-14 text-gray-700"
      >
        {subtitle}
      </p>
      <ul
        className={
          "lg:flex flex-row items-stretch md:gap-5 pb-5 max-w-7xl px-5 mx-auto"
        }
      >
        {successes.map(
          (
            { node: { frontmatter: elem, html } }: successProps,
            index: number
          ) => {
            return (
              <li
                key={index}
                className="flex-1 rounded-xl shadow-large overflow-hidden mb-5 md:mb-0 bg-white"
              >
                <header className="bg-gray-900 md:flex items-center text-white pl-6 p-5 gap-x-4">
                  <div>
                    <span
                      className={
                        "leading-tight inline-flex md:flex gap-1 items-center px-3 py-2 rounded-full border border-data-500 text-xs"
                      }
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: elem.svg }}
                        className="mr-2"
                      ></div>
                      {elem.theme}
                    </span>
                  </div>
                  <div className="mt-5 md:mt-0">
                    <span className={"block text-h4 font-medium font-sporting"}>
                      {elem.goal}
                    </span>
                  </div>
                </header>
                <div className={"pl-6 p-5"}>
                  <span
                    data-aos="custom"
                    className="translate-right text-2xl font-sporting mb-4 font-bold block lg:min-h-[65px]"
                    style={{ transitionDelay: `${0.2 + index * 0.1}s` }}
                  >
                    {elem.title}
                  </span>
                  <ul
                    data-aos="custom"
                    style={{ transitionDelay: `${0.2 + index * 0.1}s` }}
                  >
                    {elem.listitems &&
                      elem.listitems.map((item: string, index: number) => {
                        return (
                          <li
                            key={index}
                            className={
                              "flex justify-self-start items-center gap-3 mb-2.5 md:mb-3 bg-gray-50 text-sm rounded-10px md:rounded-full px-3.5 py-2.5"
                            }
                          >
                            <ArrowRight
                              className={
                                "fill-current inline-block w-5 text-gray-900"
                              }
                            />
                            {item}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </li>
            );
          }
        )}
      </ul>

      {/*{solutions.map(({node: {frontmatter: elem, html}}: solutionProps, index: number) => {*/}
      {/*  return (*/}
      {/*    <ImageContent*/}
      {/*      key={index}*/}
      {/*      title={elem.title}*/}
      {/*      length={solutions.length}*/}
      {/*      subtitle={elem.subtitle}*/}
      {/*      className={"lg:py-16"}*/}
      {/*      content={html}*/}
      {/*      textFirst={true}*/}
      {/*      parallaxImages={elem.parallaxImages}*/}
      {/*      imageUrl={elem.image}*/}
      {/*      reverse={index % 2 != 0}*/}
      {/*    />*/}
      {/*  );*/}
      {/*})}*/}
    </section>
  );
};

export default listBannerContent;
