import React from "react";
import { HeadingFrontMatter } from "types/graphql";
import ArrowDown from "assets/svg/arrow-down.svg";
import ArrowRight from "assets/svg/arrow-right.svg";
import HomeSlider from "components/homeSlider";
import AtomIcon from "assets/svg/atom.svg";
import { scroller } from 'react-scroll';
import PlusIcon from "assets/svg/plus.svg";

type introHeadingProps = Omit<HeadingFrontMatter, "format"> & {
  content: string;
};

const scrollTo = () => {
  scroller.scrollTo('second', {
    duration: 600,
    delay: 0,
    smooth: 'easeInOutQuart'
  })
}

const introData = ({ title, content }: introHeadingProps) => {
  return (
    <section id="intro" className="js-section relative flex flex-col lg:flex-row h-auto lg:h-screen">
      <div className="flex flex-row w-full lg:flex-nowrap flex-wrap">
        <div className="relative flex flex-col w-full lg:w-3/5 2xl:w-1/2 justify-center bg-no-repeat hero-data-background" style={{ backgroundImage: "url('/data/hero-section-background.png')" }}>
          {/* <img src="/data/hero-section-background.png" alt="hero-section-background" className="w-400px h-auto" /> */}
          <article className="mt-auto lg:pl-12 px-4 py-9 lg:pt-7 lg:pb-0">
            <p
              data-aos="custom"
              className="mb-3 lg:mb-10 fade rounded-5 border border-gray-900 inline-flex px-2 py-2 items-center gap-1 uppercase bg-white">
              <AtomIcon className="w-3 h-3 shrink-0" />
              <span className="text-gray-900 font-bold text-caption2">{title}</span>
            </p>
            <div
              className='overflow-hidden'
              dangerouslySetInnerHTML={{ __html: content }} />
          </article>
          <div
            className="hidden lg:block w-full bg-gray-50 hover:bg-gray-100 py-3 lg:py-6 pl-2 lg:pl-10 mt-auto order-1 lg:order-none cursor-pointer transition-colors duration-05 ease-ease"
            onClick={scrollTo}
          >
            <a>
              <span className="label1 text-gray-600 flex items-center gap-x-2 select-none">
                <ArrowDown className="animate-fadeDown"/>
                Découvrir
              </span>
            </a>
          </div>
        </div>
        <figure className="w-full lg:w-2/5 2xl:w-1/2 h-[19rem] md:h-[34rem] lg:h-full relative">
          <div className="w-full h-full transition-image relative" data-aos="custom">
            <HomeSlider />
            <span className="transition-layer" />
          </div>
        </figure>
      </div>
    </section>
  );
};

export default introData;
