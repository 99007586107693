import React from "react";
import { graphql } from "gatsby";
import { globalQuery } from "types/graphql";
import ListTitles from "components/sections/listTitles";
import HeroImage from "components/sections/hero";
import NumbersLine from "components/sections/numbersLine";
import ClientsData from "components/sections/clientsData";
import IntroData from "components/sections/introData";
import QuoteData from "components/sections/quoteData";
import {Seo} from "components/seo";
import {StaticImage} from "gatsby-plugin-image";
import GoalsData from "components/sections/goalsData";
import SolutionsData from "components/sections/solutionsData";
import AnalysisData from "components/sections/analysisData";
import TeamData from "components/sections/teamData";
import ListSuccess from "components/sections/listSuccess";
import ActiveScollData from "components/activeScrollData";

export default function Data({ data }: { data: globalQuery }) {
  const {
    allMarkdownRemark: { edges: sectionsData },
  } = data;
  return (
    <>
      <ActiveScollData />

      {sectionsData.map(

        ({ node: {frontmatter: elem, html } }, index: number) => {
          if (elem.format == "introdata") {
            return (
              <React.Fragment key={index}>
                <Seo title={elem.metaTitle as string} description={elem.metaDescription} />
                <IntroData title={elem.title} content={html} />
              </React.Fragment>
            )
          }

          if (elem.format == "titles") {
            return (
              <ListTitles
                key={index}
                titles={elem.titles}
                listtitle={elem.listtitle}
                listcontent={elem.listcontent}
              />
            );
          }
          if (elem.format == "numbers") {
            return <NumbersLine key={index} listnumbers={elem.listnumbers} />;
          }
          if (elem.format == "heroimage") {
            return (
            <div key={index} className={"grid transition-image"} data-aos="custom">
              <StaticImage
                layout="fullWidth"
                alt="Equipe La Fabrique VingtCinq"
                src={'../../static/home/VingtCinq-Fabrique-digitale-equipe-office.jpg'}
              />
              <span className="transition-layer" data-key={index} style={{transitionDelay: '0.3s'}}/>
            </div>
            )
          }

          if (elem.format == "solutions") {
            return (
              <SolutionsData
                key={index}
                title={elem.title}
                subtitle={elem.subtitle}
                informations={elem.informations}
              />
            );
          }

          if (elem.format == "analysis") {
            return (
              <AnalysisData
                key={index}
                title={elem.title}
                subtitle={elem.subtitle}
                first_definition={elem.first_definition}
                second_definition={elem.second_definition}
              />
            )
          }

          if (elem.format == "team_data") {
            return (
              <TeamData
                key={index}
                title={elem.title}
                subtitle={elem.subtitle}
              />
            )
          }

          if (elem.format == "goals") {
            return (
              <GoalsData
                key={index}
                title={elem.title}
                subtitle={elem.subtitle}
                content={html}
                listtitle={elem.listtitle}
                listcontent={elem.listcontent}
                buttonlabel={elem.buttonlabel}
              />
            );
          }

          if (elem.format == "listsuccess") {
            return (
              <ListSuccess
                key={index}
                parallaxImages={elem.parallaxImages}
                title={elem.title}
                subtitle={elem.subtitle}
              />
            );
          }

          if (elem.format == "quote") {
            return (
              <QuoteData
                id="quote"
                key={index}
                title={elem.title}
                subtitle={elem.subtitle}
                author={elem.author}
                role={elem.role}
                content={html}
                imageUrl={elem.imageUrl}
                parallaxImages={elem.parallaxImages}
              />
            );
          }
          if (elem.format == "clients") {
            return (
              <ClientsData key={index} title={elem.title} subtitle={elem.subtitle} />
            );
          }



          if (elem.format == "herohome") {
            return (
              <HeroImage
                key={index}
                title={elem.title}
                content={html}
                withTransition={false}
                buttonlabel={elem.buttonlabel}
                imageUrl={elem.imageUrl}/>
            )
          }
        })
      }
    </>
  )
}

export const pageQuery = graphql`query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/data/)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          html
          frontmatter {
            format
            title
            subtitle
            informations
            first_definition
            second_definition
            titles
            listtitle
            parallaxImages { 
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              speed 
              direction
              alt 
            }
            listcontent
            buttonlabel
            role
            author
            imageUrl
            listnumbers
            metaTitle
            metaDescription
            metaImage
          }
        }
      }
    }
  }
  
`;
