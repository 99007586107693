import React from "react";
import { analysisDataProps, solutionDataProps } from "types/graphql";
import Section from "../section";
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/data/analysis/)/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            number
            content
          }
        }
      }
    }
  }
`;

type AnalysisSectionDataProps = {
  title: string,
  subtitle: string,
  first_definition: string
  second_definition: string
}

const analysisData = ({
  title,
  subtitle,
  first_definition,
  second_definition
}: AnalysisSectionDataProps) => {
  const solutionsQuery = useStaticQuery(query);
  const {
    allMarkdownRemark: { edges: analysises },
  } = solutionsQuery;

  return (
    <Section
      className="js-section relative flex flex-col bg-gray-900 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: "url('/data/analysis-background.png')" }}
      id="analysis"
      full
    >
      <div className="max-w-7xl px-5">
        <h2 className="text-h2 text-center text-white font-bold">{title}</h2>
        <p className="text-subtitle1 text-center text-gray-500">{subtitle}</p>
        <div className="flex flex-wrap -mx-5 mt-50px">
          {analysises.map(({ node: { frontmatter: elem, html } } : analysisDataProps, index: number) => {
            return (
              <div key={index} className="w-full md:w-1/3 px-5 mb-10 md:mb-0">
                <div className="relative border border-white rounded-20px px-30px py-12 text-center bg-black h-full">
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-3.5 py-2 rounded-full bg-white max-w-full">
                    <p className="text-caption1 text-gray-900 font-medium whitespace-nowrap max-w-full">{elem.title}</p>
                  </div>
                    <h3 className="text-h2 text-white font-bold">{elem.number}</h3>
                    <div className="text-white text-b2 mt-5" dangerouslySetInnerHTML={{ __html: elem.content }} />
                </div>
              </div>
            )
          })}
        </div>
        <div className="mt-50px text-center">
          <p className="text-b4 text-white">{first_definition}</p>
          <p className="text-b4 text-white">{second_definition}</p>
        </div>
      </div>
    </Section>
  );
};

export default analysisData;
