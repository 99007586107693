import React from "react";
import Section from "../section";
import Heading from "../headings";
import ArrowRight from "assets/svg/arrow-right.svg";
import { Parallax } from 'react-scroll-parallax'

import { QuoteFrontMatter } from "types/graphql";
import classNames from "classnames/bind";
import { GatsbyImage, getImage } from "gatsby-plugin-image";



type quoteContentProps = Omit<QuoteFrontMatter, "format"> & {
  content: string
  reverse?: boolean
  length?: number
  sectionUrl?: string
  id?: string
  className?:string
  textFirst?: boolean
};

const quoteData = ({
    author,
    role,
    content,
    imageUrl,
    parallaxImages,
    reverse,
    textFirst,
    length = 0,
    sectionUrl,
    id,
    className,
}: quoteContentProps) => {
  const classFigure =
    length !== 0
      ? reverse
        ? " lg:mr-0 lg:ml-auto mx-auto"
        : " lg:ml-0 lg:mr-auto mx-auto"
      : "mx-auto";

  return (
    <Section
      id={id}
      className={classNames("js-section bg-data-500 overflow-hidden ", className,{
        'pt-10': length === 0
      })}
      full
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-28">
        { parallaxImages && parallaxImages.length > 0 && (
          <div className="relative w-full">
            { parallaxImages.map((item: any, index: number) =>
              {
                let image = getImage(item.image)

                return image ? <Parallax
                  key={index}
                  className={classNames('w-full block', { 'absolute top-0 left-0': index !== 1, 'relative z-1': index === 1, 'z-2': index >= 2})}
                  translateX={item.direction === 'horizontal' ? [0, item.speed] : [0, 0]}
                  translateY={item.direction !== 'horizontal' ? [item.speed, 0 ] : [0, 0]}
                >
                  <GatsbyImage
                    image={image}
                    alt={item.alt ?? 'image'}
                  />
              </Parallax> : <></>})}
          </div>
        )}
        {!parallaxImages && imageUrl && (
          <img
            className={classFigure}
            src={imageUrl}
          />
        )}
        <div
          className={classNames("max-w-prose relative", {
            "lg:col-start-1 lg:row-start-1": reverse,
            '-order-1 lg:order-none': textFirst
          })}
        >
          <figure>
              {content && (
                <blockquote >
                    <div
                      data-aos="custom"
                      className="image-content overflow-hidden translate text-h2 font-sporting"
                      dangerouslySetInnerHTML={{__html: content}}
                    />
                </blockquote>
              )}
            <figcaption className={"border-l border-gray-900 pl-3 text-gray-900 mt-6"}>
              <cite className={"not-italic"}>
                {author && (<span className="block text-h3 leading-6 font-medium">{author}</span>)}
                {role && (<span className="block text-caption3 font-medium uppercase ">{role}</span>)}
              </cite>
            </figcaption>
          </figure>

          {sectionUrl && (
            <button
              className="bg-gray-900 rounded-full w-16 h-16 mt-2"
              type="button"
            >
              <ArrowRight className="p-4 fill-yellow"/>
            </button>
          )}
        </div>
      </div>
    </Section>
  );
};

export default quoteData;
