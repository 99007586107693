import React from "react";
import { teamDataProps } from "types/graphql";
import Section from "../section";
import { graphql, useStaticQuery } from "gatsby";
import MemberData from "components/memberData";

const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/data/team/)/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            job
            working_years
            list
            image
          }
        }
      }
    }
  }
`;

type TeamDataSectionDataProps = {
  title: string,
  subtitle: string
}

const teamData = ({
  title,
  subtitle,
}: TeamDataSectionDataProps) => {
  const solutionsQuery = useStaticQuery(query);
  const {
    allMarkdownRemark: { edges: analysises },
  } = solutionsQuery;

  return (
    <Section id="team" className="js-section relative flex flex-col bg-white" full>
      <div className="max-w-7xl px-5">
        <h2 className="text-h2 text-center text-gray-900 font-bold">{title}</h2>
        <p className="text-subtitle1 text-center text-gray-700 mt-5 font-medium">{subtitle}</p>
        <div className="flex flex-wrap -mx-5 mt-50px">
        {analysises.map(({ node: { frontmatter: elem, html } } : teamDataProps, index: number) => {
          return (
            <div
              key={index}
              className="w-full md:w-1/3 px-5"
            >
              <img className="w-245px h-auto mx-auto mb-35px" src={elem.image} alt={elem.title} />
              <div className="text-center">
                <h3 className="text-h3 font-bold">{elem.title}</h3>
                <p className="text-caption1 font-medium text-gray-700">{elem.job}</p>
                {elem.working_years && (
                  <div className="h-6 px-5px py-3px rounded-full bg-data-500 inline-flex items-center my-2.5">
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 0L9.31235 3.81733L13.6574 4.83688L10.7415 8.21567L11.1145 12.6631L7 10.934L2.8855 12.6631L3.25854 8.21567L0.342604 4.83688L4.68765 3.81733L7 0Z" fill="black"/>
                    </svg>
                    <p className="text-caption-1 text-gray-900 ml-1 font-medium">{elem.working_years}</p>
                  </div>
                )}
                <div className="flex flex-wrap items-center justify-center -mx-1 mt-2.5">
                  {elem.list.map((item: string, index: number) => {
                    return (
                      <div key={index} className="px-1 mb-2">
                        <div className="inline-flex items-center h-6 bg-gray-50 rounded-full px-7px py-1">
                          <p className="text-caption-1 text-gray-900 font-medium">
                            {item}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}
        </div>
      </div>
    </Section>
  );
};

export default teamData;
