import React from "react";
import Section from "../section";
import Heading from "components/headings";
import { graphql, useStaticQuery } from "gatsby";
import { ClientsFrontMatter } from "types/graphql";
import { GatsbyImage } from "gatsby-plugin-image";

type clientsProps = Omit<ClientsFrontMatter, "format">;

const clientsData = ({ title, subtitle }: clientsProps) => {
  const Images = useStaticQuery(graphql`
    {
      allFile(filter: { dir: { regex: "src/images/clients/" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  `);
  return (
    <section className="js-section text-center py-120px" id="clients">
      <Heading headingLevel={"h2"} className={"mb-4"}>{title}</Heading>
      <Heading className="text-gray-700 mb-8">{subtitle}</Heading>
      <div className="flex space-x-16 overflow-hidden group">
        <div className="flex animate-loop-scroll space-x-16 group-hover:paused">
          {Images.allFile.edges.map(
            (edge: { [k: string]: any }, index: number) => {
              return (
                <figure data-aos="fade-up" key={index} className="w-max select-none"
                        style={{transitionDelay: `${0.1 + (index * 0.1)}s`}}>
                  <GatsbyImage
                    image={edge.node.childImageSharp.gatsbyImageData}
                    alt={'logo ' + edge.node.name}
                  />
                </figure>
              );
            }
          )}
        </div>
        <div className="flex animate-loop-scroll space-x-16 group-hover:paused">
          {Images.allFile.edges.map(
            (edge: { [k: string]: any }, index: number) => {
              return (
                <figure data-aos="fade-up" key={index} className="w-max select-none"
                        style={{transitionDelay: `${0.1 + (index * 0.1)}s`}}>
                  <GatsbyImage
                    image={edge.node.childImageSharp.gatsbyImageData}
                    alt={'logo ' + edge.node.name}
                  />
                </figure>
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};

export default clientsData;
