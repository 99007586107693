
import {graphql, useStaticQuery} from "gatsby";
import React, { useState, useEffect, useRef } from "react";

const ActiveScollData = () => {

  const [isIntersecting, setIsIntersecting] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  // const observer = new IntersectionObserver(
  //   ([entry]) => {
  //     setIsIntersecting(entry.isIntersecting);
  //   },
  //   { rootMargin: "-300px" }
  // );

  useEffect(() => {
    let sections = document.querySelectorAll('.js-section')

    let observer = new IntersectionObserver((entries, observer)=>{
      entries.forEach( entry => {      
        if(entry.isIntersecting){
          const id = entry.target.getAttribute('id')
          // let target = entry.target.classList[1]
          document.querySelectorAll('.js-active-scroll').forEach((element: any)=>{
            element.style.backgroundColor = 'rgba(255, 255, 255, 0.4)'
          })
          document.querySelector(`[data-target="${id}"]`).style.backgroundColor = 'rgba(255, 255, 255, 1)';
        }
      })
    }, {
      root: null,
      rootMargin: `-300px`,
      threshold: 0
    })
  
    sections.forEach(section => observer.observe(section))
  
  }, []);

  

  const onClick = (e: any) => {
    e.preventDefault();
    console.log(e.target.getAttribute('data-target'))
    const id = e.target.getAttribute('data-target')
    const element = document.getElementById(id);
    console.log('target', id)
    console.log('element', element)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <nav className="hidden md:block fixed z-10 top-1/2 right-0 trasnform -translate-y-1/2 mr-5">
      <ul className="">
        <li className="flex items-center justify-end mb-2">
          <a
            className="js-active-scroll h-30px inline-flex items-center px-2 rounded-full uppercase text-gray-800 text-caption2 font-medium duration-500 cursor-pointer hover:bg-white"
            data-target="intro"
            onClick={(e) => onClick(e)}
          >
            Intro
          </a>
        </li>
        <li className="flex items-center justify-end mb-2">
          <a
            className="js-active-scroll h-30px inline-flex items-center px-2 rounded-full uppercase text-gray-800 text-caption2 font-medium duration-500"
            data-target="analysis"
            onClick={(e) => onClick(e)}
          >
            Constats
          </a>
        </li>
        <li className="flex items-center justify-end mb-2">
          <a
            className="js-active-scroll h-30px inline-flex items-center px-2 rounded-full uppercase text-gray-800 text-caption2 font-medium duration-500"
            data-target="solutions"
            onClick={(e) => onClick(e)}
          >
            Solutions
          </a>
        </li>
        <li className="flex items-center justify-end mb-2">
          <a
            className="js-active-scroll h-30px inline-flex items-center px-2 rounded-full uppercase text-gray-800 text-caption2 font-medium duration-500"
            data-target="team"
            onClick={(e) => onClick(e)}
          >
            Team
          </a>
        </li>
        <li className="flex items-center justify-end mb-2">
          <a
            className="js-active-scroll h-30px inline-flex items-center px-2 rounded-full uppercase text-gray-800 text-caption2 font-medium duration-500"
            data-target="goals"
            onClick={(e) => onClick(e)}
          >
            Ambitions
          </a>
        </li>
        <li className="flex items-center justify-end mb-2">
          <a
            className="js-active-scroll h-30px inline-flex items-center px-2 rounded-full uppercase text-gray-800 text-caption2 font-medium duration-500"
            data-target="success"
            onClick={(e) => onClick(e)}
          >
            Succès
          </a>
        </li>
        <li className="flex items-center justify-end mb-2">
          <a
            className="js-active-scroll h-30px inline-flex items-center px-2 rounded-full uppercase text-gray-800 text-caption2 font-medium duration-500"
            data-target="quote"
            onClick={(e) => onClick(e)}
          >
            Citation
          </a>
        </li>
        <li className="flex items-center justify-end mb-2">
          <a
            className="js-active-scroll h-30px inline-flex items-center px-2 rounded-full uppercase text-gray-800 text-caption2 font-medium duration-500"
            data-target="clients"
            onClick={(e) => onClick(e)}
          >
            Clients
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default ActiveScollData;
